import { Pipe, PipeTransform } from '@angular/core';

import type { SetRequired } from 'type-fest';

export interface ToolbarButtonOptions {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  stylingMode?: 'contained' | 'outlined' | 'text';
}

@Pipe({ name: 'disableToolbarButton', pure: true, standalone: true })
export class DisableToolbarButtonPipe implements PipeTransform {
  transform(options: Omit<ToolbarButtonOptions, 'disabled'>, disabled: boolean): SetRequired<ToolbarButtonOptions, 'disabled'> {
    return {
      ...options,
      disabled,
    };
  }
}
